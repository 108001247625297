<template>
  <section class="container ml-[115px] max-w-[1500px] text-left device-page">
    <div class="flex justify-center" v-if="loading && !error">
      <RbLoadingSpinner />
    </div>
    <Alert variant="red" icon="exclamation" v-if="error" class="mx-5">{{ error }}</Alert>
    <DevicePageHeader v-if="!loading" :device="deviceHeaderProps" />
    <hr class="mb-6" />
    <div class="px-4">
      <DeviceUsersV2 />
    </div>
  </section>
</template>

<script>
import DevicePageHeader from '@/components/DevicePageHeader';
import DeviceUsersV2 from '@/components/DeviceUsersV2';
import Alert from '@/components/ui/Alert';

export default {
  name: 'Device',
  components: {
    Alert,
    DevicePageHeader,
    DeviceUsersV2
  },
  data() {
    return {
      deviceId: this.$route.params.id,
      device: {},
      errorMessage: null
    };
  },
  computed: {
    error() {
      return this.$store.state.devices.error;
    },
    loading() {
      return this.$store.state.devices.loading;
    },
    deviceHeaderProps() {
      const device = this.$store.getters['devices/getDevice'];

      return {
        id: device.id,
        deviceName: device.name,
        organizationName: device.organizationName,
        serialNumber: device.serialNumber,
        lastSynced: device.syncedAt || null,
        environmentUrl: device.info?.environmentUrl || null,
        apkVersion: device.info?.apkVersion || null,
        active: device.active,
        modelName: device.info?.modelName,
        webVersion: device.info?.webAppCommitHash || null,
        deploymentGroup: device.deploymentGroup || null,
        deploymentUrl: device.deploymentGroup ? device.deploymentUrl : null
      };
    }
  },
  async mounted() {
    await this.readDevice();
    this.device = this.$store.getters['devices/getDevice'];
  },
  methods: {
    async readDevice() {
      await this.$store.dispatch('devices/readDevice', this.deviceId);
    },
    async getDeviceUsers() {
      await this.$store.dispatch('devices/getDeviceUsers', this.deviceId);
    }
  }
};
</script>
